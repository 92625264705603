import React, { useState, useEffect, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import {
  PencilIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronRightIcon,
  CheckIcon,
} from '@heroicons/react/solid';
import { arrayHasItems, getDetailInformationMessage } from '../../helpers';
import { useDebounce, hasAccessToCourse } from '../../helpers/index';
import { PencilAltIcon, MenuAlt4Icon, MinusIcon, PlusIcon } from '@heroicons/react/outline'
import {
  deleteLessonAsync,
  deleteModuleAsync,
  getCompletedLessonsAsync,
  markLessonAsCompletedAsync,
  markLessonAsUncompletedAsync,
  setActiveLesson,
  setActiveModule,
} from './../course/courseSlice'
import FormModal from '../../components/general/FormModal'
import ModuleForm from './ModuleForm'
import LessonForm from './LessonForm'
import NoAccess from '../../components/general/NoAccess';
import MarkdownPreview from '../../components/general/MarkdownPreview'
import { useHistory, useParams } from 'react-router-dom'
import TypeformEmbed from './../course/TypeformEmbed'
import Btn from '../../components/general/Btn';
import classNames from 'classnames';
import ProgressBar from '../../components/general/ProgressBar';
import { getCourseContentAsync, reorderModulesAndLessons, getUserAccessToCurrentCourse, cleanUpCourseData } from './coursesListSlice';
import ReactTooltip from 'react-tooltip'
import { COURSE_NOT_FOUND } from '../../constants/errors';
import { NotFound } from '../../components/general/NotFound';
import { CourseSearch } from '../../components/courses/CourseSearch';
import { MainLoader } from '../../components/Loaders/MainLoader';
import { InformationIcon } from '../../components/general/InformationIcon';
import { BackToCourses } from '../../components/courses/BackToCourses';
import ModuleCompleted from './ModuleCompleted';
import LessonCompleted from './LessonCompleted';

const CourseViewing = () => {
  const history = useHistory();
  const { slug, moduleId, lessonId } = useParams();

  const dispatch = useDispatch()
  const { loading, activeModule, activeLesson } = useSelector(
    (state) => state.course
  )

  const { 
    courseDetails, 
    error, 
    loading: courseListLoading, 
    hasAccess, 
    hasAccessLoadComplete,
    courseDetailsLoadComplete
  } = useSelector(
    (state) => state.courseList
  )
  
  const [courseModules, setCourseModules] = useState([]);

  useEffect(() => {
    ReactTooltip.rebuild()
    return () => {
      dispatch(cleanUpCourseData());
      dispatch(setActiveModule({ module: null }))
      dispatch(setActiveLesson({ lesson: null }))
    }
  }, [])

  useEffect(() => {
    setCourseModules(arrayHasItems(courseDetails?.Modules) ? courseDetails.Modules : []);
  }, [courseDetails])

  const { currentUser, rainaChatIsVisible } = useSelector((state) => state.auth)

  const isAdmin = currentUser?.role === "admin"
  const [moduleModalOpen, setModuleModalOpen] = useState(false)
  const [editingModule, setEditingModule] = useState(null)

  const [expandedModules, setExpandedModules] = useState(null);

  const [lessonModalOpen, setLessonModalOpen] = useState(false)
  const [editingLesson, setEditingLesson] = useState(null)

  const [isReorderingMode, setIsReorderingMode] = useState(false)

  const [currentModule, setCurrentModule] = useState(null);
  const [originalModules, setOriginalModules] = useState([])

  // search
  const [modulesFiltered, setModulesFiltered] = useState(courseModules || []) // for the module search
  const [search, setSearch] = useState('')

  const debouncedSearchValue = useDebounce(search, 500);

  // // search filter
  useEffect(() => {
    if (!courseDetails) return
    const modulesTemp = arrayHasItems(courseDetails?.Modules) ? courseDetails.Modules : []
    setModulesFiltered(modulesTemp)
  }, [courseDetails])

  useEffect(() => {
    if (expandedModules !== null || !activeModule) return;
    setExpandedModules([activeModule.id])
  }, [expandedModules, activeModule]);
  

  useEffect(() => {
    if (!isAdmin && isReorderingMode) {
      handleCancelReordering()
    }
  }, [currentUser?.role, isReorderingMode])
  
  useEffect(() => {
    if (slug) {
      dispatch(getUserAccessToCurrentCourse({ slug }))
    }
  }, [dispatch, slug])
  
  useEffect(() => {
    if (hasAccess) {
      dispatch(getCompletedLessonsAsync())
      if (slug) {
        dispatch(getCourseContentAsync({ slug, search: debouncedSearchValue }))
      }
    }
  }, [dispatch, slug, hasAccess, debouncedSearchValue])

  useEffect(() => {
    const numberLessonId = parseInt(lessonId, 10)
    const numberModuleId = parseInt(moduleId, 10)

    if (arrayHasItems(courseModules) && moduleId && lessonId) {
      const _module = courseModules.find((mod) => mod.id === numberModuleId)
      let lesson = undefined;
      if (arrayHasItems(_module?.Lessons)) {
        lesson = _module?.Lessons.find((les) => les.id === numberLessonId)
      }

      dispatch(setActiveModule({ module: _module }))
      dispatch(setActiveLesson({ lesson }))
    }

    if (courseModules.length && (!moduleId || !lessonId)) {
      const firstLesson = getFirstLesson(courseModules);
      if (firstLesson) {
        dispatch(setActiveModule({ module: firstLesson.ModuleId }))
        dispatch(setActiveLesson({ lesson: firstLesson }))
        history.replace(`/courses/${slug}/${firstLesson.ModuleId}/${firstLesson.id}`)
      }
    }
  }, [dispatch, lessonId, moduleId, courseModules, slug, history])

  // useEffect(() => {
  //   if (isAdmin) dispatch(readLessonOptionsAsync())
  // }, [dispatch, currentUser.role]);

  const [boxShow, setBoxShow] = useState(true);

  const toggleModule = (id) => {
    if (expandedModules.includes(id)) {
      setExpandedModules(expandedModules.filter(i => i !== id));
    } else {
      setExpandedModules([...expandedModules, id]);
    }
  }

  const onDragEnd = (result) => {
    const { destination, source, type } = result

    if (
      !destination ||
      (destination.droppableId === source.droppableId && destination.index === source.index)
    ) {
      return
    }

    if (type === 'module') {
      const newModules = Array.from(modulesFiltered)
      const [reorderedModule] = newModules.splice(source.index, 1)
      newModules.splice(destination.index, 0, reorderedModule)
      setModulesFiltered(newModules)
    } else if (type === 'lesson') {
      const newModules = JSON.parse(JSON.stringify(modulesFiltered)) // Deep copy
      const sourceModuleIndex = newModules.findIndex((m) => m.id.toString() === source.droppableId)
      const destModuleIndex = newModules.findIndex(
        (m) => m.id.toString() === destination.droppableId
      )

      const sourceModule = newModules[sourceModuleIndex]
      const destModule = newModules[destModuleIndex]

      const [movedLesson] = sourceModule.Lessons.splice(source.index, 1)
      destModule.Lessons.splice(destination.index, 0, movedLesson)

      if (sourceModuleIndex !== destModuleIndex) {
        movedLesson.ModuleId = destModule.id
      }

      setModulesFiltered(newModules)
    }
  }

  const nextLesson = getNextLesson(activeLesson, courseModules)

  const enterReorderingMode = () => {
    setOriginalModules([...modulesFiltered])
    setIsReorderingMode(true)
  }

  const handleCancelReordering = () => {
    setModulesFiltered(originalModules)
    setIsReorderingMode(false)
  }

  const handleSaveReordering = () => {
    const modulesWithFixedPositions = modulesFiltered.map((module, moduleIndex) => {
      return {
          ...module,
          position: moduleIndex + 1,
          Lessons: module.Lessons.map((lesson, lessonIndex) => ({
            ...lesson,
            position: lessonIndex + 1,
          })),
      };
    });

    const newActiveLesson = modulesWithFixedPositions
      .flatMap((module) => module.Lessons)
      .find((lesson) => lesson.id === activeLesson.id)

    dispatch(
      reorderModulesAndLessons({
        modules: modulesWithFixedPositions,
        courseId: courseDetails.id,
        slug
      })
    )

    // Update the URL if the active lesson has moved to a different module
    if (newActiveLesson.ModuleId !== activeLesson.ModuleId) {
      history.replace(`/courses/${slug}/${newActiveLesson.ModuleId}/${activeLesson.id}`)
    }
    setIsReorderingMode(false)
  }

  const makeToCompleteLesson = () => {
    dispatch(
      markLessonAsCompletedAsync({
        lessonId: activeLesson.id,
        courseSlug: slug,
        search: debouncedSearchValue 
      })
    )
  }

  const makeToUncompleteLesson = () => {
    dispatch(markLessonAsUncompletedAsync({ 
      lessonId: activeLesson.id, 
      courseSlug: slug,
      search: debouncedSearchValue  
    }))
  }

  const handleCompletedAction = () => {
    activeLesson?.completed === "false"
      ? makeToCompleteLesson()
      : makeToUncompleteLesson()
  }

  if (error === COURSE_NOT_FOUND) {
    return (
      <NotFound
          title={COURSE_NOT_FOUND}
          titleButton={"Go back to courses"}
          linkButton={"/courses"}
      />
    )
  }

  if (!hasAccess && hasAccessLoadComplete) {
    return <NoAccess />
  }

  if (!hasAccessLoadComplete || !courseDetailsLoadComplete) {
    return <MainLoader />
  }

  // check access while user role changed by admin
  if (courseDetails && !hasAccessToCourse(courseDetails, currentUser?.role || 'basic')) {
    return <NoAccess />
}

  // const moduleCompleted = (lessons) => arrayHasItems(lessons) && lessons.filter((lesson) => lesson.completed === 'true').length === lessons.length;
  
  return (
    <>
      <div className={classNames([
        "pt-7.5 md:pt-9.5",
        {
          "pb-16 raina-min:pb-24": rainaChatIsVisible,
          "pb-6 md:pb-12": !rainaChatIsVisible
        }
      ])}>
        <div className="container">
          <div className="flex flex-row flex-wrap items-center gap-3">
            <div className="w-full md:w-70">
              {courseDetails?.title && (
                <h1 className="w-full md:w-auto text-lg md:text-xl leading-38 font-semibold tracking-1 font-base">
                  {courseDetails.title}
                </h1>
              )}
            </div>
            <div className="ml-auto w-full md:w-3/12 flex flex-wrap items-center gap-6 sm:gap-4 flex-col-reverse md:flex-row">
              <CourseSearch
                value={search}
                onChange={setSearch}
                disabled={isReorderingMode || (!arrayHasItems(modulesFiltered) && !search)}
                isReorderingMode={isReorderingMode}
                placeholder={"Search Lessons"}
              />
            </div>
          </div>
          <div className="my-2">
            <BackToCourses />
          </div>
          <div className="grid xl:grid-cols-3 gap-12 xl:gap-5">
            {activeLesson ? (
              <div className="xl:col-start-2 xl:col-end-4 xl:row-start-1">
                <div className="lg:bg-white lg:rounded-2xl lg:border lg:border-solid lg:border-primary lg:border-opacity-20 lg:shadow-box lg:pt-3 lg:px-3 lg:pb-3">
                  <div className="lg:mx-3.5 lg:relative">
                      {isAdmin && (
                            <div className={classNames([
                              "lg:absolute lg:w-full lg:top-1 lg:left-0 lg:z-1 flex items-center justify-end gap-4 pb-4 xl:pb-0",
                              {
                                "lg:justify-between": getDetailInformationMessage(activeLesson),
                                "lg:justify-end": !getDetailInformationMessage(activeLesson)
                              }
                            ])}>
                              <InformationIcon 
                                tooltipId={`${activeLesson?.title}_${activeLesson?.id}`}
                                tooltipMessage={getDetailInformationMessage(activeLesson)}
                                type="active-lesson"
                              />
                              {!isReorderingMode && (
                                <span 
                                  disabled={isReorderingMode}
                                  onClick={() => {
                                    setEditingLesson(activeLesson)
                                    setLessonModalOpen(true)
                                  }}
                                  className="md:border md:border-solid md:border-primary md:border-opacity-20 bg-white w-8 h-8 flex items-center justify-center rounded-lg text-dark hover:text-secondary transition-colors cursor-pointer"
                                >
                                  <PencilAltIcon className="w-5 h-5" />
                                </span>
                              )}
                            </div>
                        )}
                    <HiddenDivWithExtraTailwindClasses />
                    {activeLesson && activeLesson.content ? (
                      <>
                        <TypeformEmbed lesson={activeLesson} />
                        <div className={classNames([
                          "wysiwyg pt-0",
                          {
                            "lg:pt-10": isAdmin
                          }
                        ])}>
                          {activeLesson?.title && <h3 className="font-bold text-2xl my-3">{activeLesson.title}</h3>}
                          <MarkdownPreview markdown={activeLesson.content} withoutAnchors={true} />
                        </div>
                      </>
                    ) : null}
                    <div
                    className={classNames([
                      'grid grid-cols-1 pt-12',
                      {
                        'grid sm:grid-cols-2 gap-4': nextLesson
                      }
                    ])}
                  >
                    <Btn
                      disabled={loading || isReorderingMode}
                      onClick={() => {
                        handleCompletedAction()
                      }}
                    >
                      {activeLesson?.completed === "true" ? 'Mark as Uncompleted' : 'Mark as Completed'}
                    </Btn>
                    {nextLesson && (
                        <Btn
                          onClick={() => {
                            dispatch(setActiveLesson({ lesson: nextLesson }))
                            history.push(`/courses/${slug}/${nextLesson.ModuleId}/${nextLesson.id}`)
                          }}
                          row={true}
                          variant="secondary"
                          outline={true}
                          disabled={isReorderingMode}
                        >
                          <span>Next Lesson</span>
                          <ChevronRightIcon className="h-4 w-4" />
                        </Btn>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="xl:col-start-1 xl:col-end-2 xl:row-start-1">
              <div className="space-y-2.5">
                {arrayHasItems(modulesFiltered) && (
                  <div className="bg-white rounded-2xl border border-solid border-primary border-opacity-20 shadow-box overflow-hidden">
                    <div className="px-5 py-4">
                      <div className="flex items-center cursor-pointer" onClick={() => setBoxShow(!boxShow)}>
                        <h4 className="flex-grow flex-shrink text-md-2 leading-25 font-title font-semibold text-dark tracking-1">{courseDetails.title}</h4>
                        {isAdmin && (
                          <div className="mx-1">
                            <InformationIcon 
                              tooltipId={`${courseDetails.title}_tooltip`} 
                              tooltipMessage={getDetailInformationMessage(courseDetails, "course-title")}
                              html={true}
                              type="course-title"
                              onClick={(e) => e.stopPropagation()}
                            />
                          </div>
                        )}
                        {!isReorderingMode ? <div className="flex-grow-0 flex-shrink-0 w-5 h-5 text-dark hover:text-secondary transition-colors cursor-pointer">
                          {boxShow ? (
                            <ChevronUpIcon className="w-5 h-5" />
                          ) : (
                            <ChevronDownIcon className="w-5 h-5" />
                          )}
                        </div> : null }
                      </div>
                      {modulesFiltered.length ? (
                        <div className="pt-5">
                          <ProgressBar count={courseDetails?.totalProgress || 0} sizeText="xs" />
                        </div>
                      ) : null}
                    </div>

                    <div className={classNames([
                      'opacity-0 h-0 transition-opacity',
                      {
                        'h-auto opacity-100 pb-3': boxShow || isReorderingMode
                      }
                    ])}>
                      <div className="mx-4 border-t border-solid">
                      </div>
                      <DragDropContext onDragEnd={onDragEnd} >
                        <Droppable droppableId="modules" type="module">
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              className="px-2.5 pt-1.5"
                            >
                              {modulesFiltered.map((module, moduleIndex) => (
                                <Draggable
                                  key={`module-${module.id}`}
                                  draggableId={`module-${module.id}`}
                                  index={moduleIndex}
                                  isDragDisabled={!isReorderingMode || !isAdmin}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      className={classNames([
                                        {
                                          "bg-white rounded-10": isReorderingMode
                                        }
                                      ])}
                                    >
                                      <div>
                                        <div 
                                          className="px-3 py-2.5 flex gap-2.5 justify-between cursor-pointer"
                                          onClick={() => {
                                            if (arrayHasItems(module.Lessons)) {
                                              if (activeModule && module.id !== activeModule.id) {
                                                dispatch(setActiveModule({ module }))
                                              }
                                              toggleModule(module.id);
                                            }
                                          }}
                                        >
                                          <div className="flex-grow flex-shrink self-center flex gap-1.5">
                                            {/* <div className="py-0.5 lg:py-0 my-px self-start">
                                              <ModuleCompleted
                                                show={moduleCompleted(module?.Lessons)}
                                              />
                                            </div> */}
                                            <p
                                              className="text-base md:text-md leading-22 text-dark font-title font-semibold tracking-1"
                                            >
                                              {module.title}
                                            </p>
                                            
                                          </div>
                                          <div className="flex items-center gap-2">
                                            {isAdmin &&
                                              (isReorderingMode ? (
                                                <div
                                                  {...provided.dragHandleProps}
                                                  className="flex justify-center items-center cursor-move flex-grow-0 flex-shrink-0 w-7.5 h-7.5 text-dark hover:text-secondary transition-colors"
                                                >
                                                  <MenuAlt4Icon className="w-5 h-5" />
                                                </div>
                                              ) : (
                                                <div className="flex gap-2">
                                                  <InformationIcon 
                                                    onClick={(e) => e.stopPropagation()}
                                                    tooltipId={`${module?.title}_${moduleIndex}_${module?.id}`} 
                                                    tooltipMessage={getDetailInformationMessage(module)}
                                                    type="module"
                                                  /> 
                                                  <button
                                                    data-tip
                                                    data-for={'add-lesson-tooltip'}
                                                    onClick={(e) => {
                                                      e.stopPropagation()
                                                      setEditingLesson(null)
                                                      setLessonModalOpen(true)
                                                      setCurrentModule(module)
                                                    }}
                                                    className="flex-grow-0 flex-shrink-0 w-7.5 h-7.5 rounded-md border border-solid border-opacity-16 hover:border-opacity-100 hover:border-secondary border-dark cursor-pointer flex items-center justify-center bg-white text-dark text-opacity-70 hover:text-opacity-100 focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
                                                  >
                                                    <ReactTooltip id="add-lesson-tooltip" effect="solid">Add New Lesson</ReactTooltip>
                                                    <PlusIcon className="w-4 h-4" />
                                                  </button>
                                                  <button
                                                    data-tip
                                                    data-for={'edit-module-tooltip'}
                                                    onClick={(e) => {
                                                      e.stopPropagation()
                                                      setEditingModule(module)
                                                      setModuleModalOpen(true)
                                                    }}
                                                    className="flex-grow-0 flex-shrink-0 w-7.5 h-7.5 rounded-md border border-solid border-opacity-16 hover:border-opacity-100 hover:border-secondary border-dark cursor-pointer flex items-center justify-center bg-white text-dark text-opacity-70 hover:text-opacity-100 focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
                                                  >
                                                    <ReactTooltip id="edit-module-tooltip" effect="solid">Edit Module</ReactTooltip>
                                                    <PencilAltIcon className="w-5 h-5" />
                                                  </button>
                                                </div>
                                              ))}
                                            {!isReorderingMode && arrayHasItems(module.Lessons) ? (
                                              <div 
                                                className="flex-grow-0 flex-shrink-0 w-5 h-5 text-dark hover:text-secondary transition-colors my-px cursor-pointer"
                                              >
                                                {expandedModules?.includes(module.id) ? (
                                                  <MinusIcon className="w-5 h-5" />
                                                ) : (
                                                  <PlusIcon className="w-5 h-5" />
                                                )}
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                        {typeof module.totalProgress === 'number' ? (
                                          <div className="mx-3 my-0.5 bg-primary bg-opacity-20">
                                            <div
                                              className="h-0.5 bg-secondary"
                                              style={{width: module.totalProgress + '%',}}
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                      <Droppable
                                        droppableId={module.id.toString()}
                                        type="lesson"
                                        isDropDisabled={!isReorderingMode}
                                      >
                                        {(provided) => {
                                          return (
                                            <div
                                              {...provided.droppableProps}
                                              ref={provided.innerRef}
                                              className={classNames([
                                                'opacity-0 h-0 transition-opacity overflow-hidden',
                                                {
                                                  'h-auto opacity-100 pt-1':
                                                    (expandedModules?.includes(module.id)) ||
                                                    isReorderingMode,
                                                  'py-2.5':
                                                    isReorderingMode && !module.Lessons?.length
                                                }
                                              ])}
                                            >
                                              {arrayHasItems(module?.Lessons) &&
                                                module.Lessons.map((lesson, lessonIndex) => (
                                                  <Draggable
                                                    key={lesson.id.toString()}
                                                    draggableId={lesson.id.toString()}
                                                    index={lessonIndex}
                                                    isDragDisabled={
                                                      !isReorderingMode ||
                                                      !isAdmin
                                                    }
                                                  >
                                                    {(provided) => (
                                                      <>
                                                        <div
                                                          ref={provided.innerRef}
                                                          {...provided.draggableProps}
                                                          className={classNames([
                                                            `flex items-center gap-2 lg:gap-3 py-2.5 px-4 rounded-10 ${
                                                              activeLesson &&
                                                              lesson.id === activeLesson.id
                                                                ? 'bg-grey-100'
                                                                : 'bg-white'
                                                            }`
                                                          ])}
                                                        >
                                                          <div className="flex-none">
                                                            <LessonCompleted
                                                              completed={lesson.completed === "true"}
                                                              active={lesson.id === activeLesson?.id && !(lesson.completed === "true")}
                                                            />
                                                          </div>
                                                          <div
                                                            className="flex-grow"
                                                            onClick={() => {
                                                              document.getElementById('main').scrollTo({
                                                                top: 0,
                                                                left: 0,
                                                                behavior: 'smooth'
                                                              })

                                                              setEditingLesson({
                                                                ...lesson
                                                              })

                                                              history.push(
                                                                `/courses/${slug}/${module.id}/${lesson.id}`
                                                              )

                                                              dispatch(setActiveLesson({ lesson }))
                                                            }}
                                                          >
                                                            <span title={lesson.title} className={classNames(["flex-grow flex-shrink text-base text-dark-500 cursor-pointer hover:text-secondary transition-colors leading-20 font-medium font-title tracking-1"],
                                                              {
                                                                "lesson-draggable-title-clamp": isReorderingMode
                                                              }
                                                            )}>
                                                              {lesson.title}
                                                            </span>
                                                          </div>
                                                          {isAdmin && (
                                                            <InformationIcon 
                                                              tooltipId={`${lesson?.title}_${lessonIndex}_${lesson?.id}`}
                                                              tooltipMessage={getDetailInformationMessage(lesson)}
                                                              type="lesson"
                                                            />
                                                          )}
                                                          <div
                                                            {...provided.dragHandleProps}
                                                            className={classNames(
                                                              'flex justify-center items-center flex-grow-0 flex-shrink-0 w-5 h-5 text-dark hover:text-secondary transition-colors',
                                                              {
                                                                'cursor-move': isReorderingMode,
                                                                hidden: !isReorderingMode
                                                              }
                                                            )}
                                                          >
                                                            <MenuAlt4Icon className="w-5 h-5" />
                                                          </div>
                                                        </div>
                                                      </>
                                                    )}
                                                  </Draggable>
                                                ))}
                                              {provided.placeholder}
                                            </div>
                                          )
                                        }}
                                      </Droppable>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  </div>
                )}

                {isAdmin &&
                  !debouncedSearchValue &&
                  !loading &&
                  (isReorderingMode ? (
                    <div className="bottom-0 md:col-start-1 md:col-end-2 md:row-start-1 sticky">
                      <div className="bg-white bottom-0 flex gap-2.5 justify-end p-2 w-full bg-white rounded-sm border border-solid border-primary border-opacity-20 shadow-box">
                        <Btn
                          loading={courseListLoading}
                          disabled={courseListLoading}
                          onClick={handleSaveReordering}
                        >
                          Save
                        </Btn>
                        <Btn variant="secondary" outline={true} onClick={handleCancelReordering}>
                          Cancel
                        </Btn>
                      </div>
                    </div>
                  ) : (
                    <div className="grid gap-2.5">
                      <Btn
                        onClick={() => {
                          setEditingModule(null)
                          setModuleModalOpen(true)
                        }}
                        row={true}
                      >
                        <div className='flex items-center justify-center rounded-md w-6 h-6' style={{ background: "color-mix(in srgb, currentColor 30%, transparent)" }} >
                          <PlusIcon className="w-4 h-4" />
                        </div>
                        <span>Add New Module</span>
                      </Btn>
                      {arrayHasItems(courseModules) ? (
                        <Btn onClick={enterReorderingMode}>
                          Manage Order
                        </Btn>
                      ) : null}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <FormModal
        open={moduleModalOpen}
        setOpen={setModuleModalOpen}
        Form={ModuleForm}
        additionalFormData={{
          courseId: courseDetails?.id,
          courseSlug: slug,
          search: debouncedSearchValue,
          courseVisibilityPremium: courseDetails?.visibleToPremium,
          courseVisibilityBasic: courseDetails?.visibleToBasic
        }}
        editingItem={editingModule}
        setEditingItem={setEditingModule}
        deleteAction={async () => {
          await dispatch(
            deleteModuleAsync({ module: editingModule, slug, search: debouncedSearchValue })
          )
        }}
      />

      <FormModal
        open={lessonModalOpen}
        setOpen={setLessonModalOpen}
        Form={LessonForm}
        editingItem={editingLesson}
        currentModule={currentModule}
        onModalClose={() => setCurrentModule(null)}
        additionalFormData={{
          courseSlug: slug,
          search: debouncedSearchValue
        }}
        setEditingItem={setEditingLesson}
        deleteAction={async () => {
          await dispatch(
            deleteLessonAsync({ lesson: editingLesson, slug, search: debouncedSearchValue })
          )
        }}
      />
    </>
  )
}

const HiddenDivWithExtraTailwindClasses = () => {
  // this element is used to solve the problem of tailwind classes used in lessons missing from the compiled css
  // lessons descriptions are in markdown, which can contain html, which can contain tailwind classes
  // the lessons markdown is stored in the DB, not in the codebase, so the tailwind classes will not be included in the css output by the build command unless they are used in the code
  return (
    <div className="hidden aspect-w-16 aspect-w-1 aspect-w-3 aspect-w-4 aspect-h-9 aspect-h-1 aspect-h-3 aspect-h-4" />
  )
}

function getFirstLesson(modules) {
  let found = null
  if (arrayHasItems(modules)) {
    modules.forEach((module) => {
      if (arrayHasItems(module.Lessons)) {
        module.Lessons.forEach((lesson) => {
          if (!found) found = lesson
        })
      }
    })
  }
  return found
}

function getNextLesson(activeLesson, modules) {
  if (!activeLesson) return null
  let found = false
  let nextLesson = null
  if (arrayHasItems(modules)) {
    modules.forEach((module) => 
      {
        if (arrayHasItems(module.Lessons)) {
          module.Lessons.forEach((l) => {
            if (found && !nextLesson) nextLesson = l
            if (l.id === activeLesson.id) found = true
          })
        }
      }
    )
  }
  return nextLesson
}

export default CourseViewing
