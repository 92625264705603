import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MenuAlt4Icon } from '@heroicons/react/outline'
import ReactTooltip from 'react-tooltip'
import classNames from 'classnames';
import FormModal from '../../components/general/FormModal'
import Btn from '../../components/general/Btn';
import {
    PlusIcon,
} from "@heroicons/react/solid";
import CourseCard from './CourseCard';
import CourseForm from './CourseForm';
import { CourseLoadingKeys, deleteCourseAsync, readManyCoursesAsync, reorderCoursesPositions } from './coursesListSlice'
import { arrayHasItems, getDetailInformationMessage, getFilteredCoursesByRole, useDebounce } from '../../helpers';
import { CourseSearch } from '../../components/courses/CourseSearch'
import { MainLoader } from '../../components/Loaders/MainLoader'
import { BackToTop } from '../../components/courses/BackToTop';

const CourseCardItem = ({ 
    courseItem, 
    isReorderingMode, 
    onClickEdit, 
    isAdmin,
}) => {
    return (
        <CourseCard
            isEditAllowed={isAdmin}
            isReorderingMode={isReorderingMode}
            onClickEdit={onClickEdit}
            countCompleted={courseItem.totalProgress || 0}
            title={courseItem?.title}
            detailInformationMessage={isAdmin ? getDetailInformationMessage(courseItem) : ""}
            description={courseItem?.description}
            status={courseItem?.status}
            image={courseItem?.coverPhotoUrl}
            slug={courseItem?.slug}
            showProgress={
                courseItem?.Modules && courseItem?.Modules.length && (courseItem.totalProgress || 0) < 100
            }
        />
    )
}

const CourseList = () => {
    const dispatch = useDispatch()
    const { loading: courseListLoading, loadingKeys: courseListLoadingKeys, courses, isFirstLoadComplete } = useSelector(
        (state) => state.courseList
    )

    const { currentUser } = useSelector((state) => state.auth)

    const currentUserRole = currentUser?.role;
    const isAdmin = currentUserRole === 'admin'
    
    const [editingCourse, setEditingCourse] = useState(null);
    const [isReorderingMode, setIsReorderingMode] = useState(false);
    // search
    const [search, setSearch] = useState('')

    const debouncedInputValue = useDebounce(search, 500);

    // search filter
    const [newCourseModalOpen, setNewCourseModalOpen] = useState(false);

    const [temporaryItems, setTemporaryItems] = useState();
    const [reorderCourses, setReorderCourses] = useState();
    const [draggedItem, setDragedItem] = useState();

    function enableReordering() {
        setIsReorderingMode(true);
        setReorderCourses(filteredCourses);
    }

    useEffect(() => {
        ReactTooltip.rebuild()
    }, [])

    useEffect(() => {
        if (currentUser?.role !== "admin") {
            setIsReorderingMode(false)
        }
      }, [currentUser?.role])
      
    useEffect(() => {
        dispatch(readManyCoursesAsync({ search: debouncedInputValue, itemsPerPage: 1000 }))
    }, [debouncedInputValue, dispatch])

    const filteredCourses = getFilteredCoursesByRole(courses, currentUserRole || 'basic');
    const renderedCourses= isReorderingMode ? temporaryItems || reorderCourses : filteredCourses;

    const onDragEnd = (result) => {
        setReorderCourses(result);
    }

    const saveSortOrder = () => {
        const courseWithNewPositions = reorderCourses.map((course, courseIndex) => {
            return {
                ...course,
                position: courseIndex + 1,
            };
        });
        dispatch(reorderCoursesPositions({ courses: courseWithNewPositions, search: debouncedInputValue, itemsPerPage: 1000 }))
        setIsReorderingMode(false)
    }

    const handleCourseEdit = (course) => {
        setNewCourseModalOpen(true);
        setEditingCourse(course);
    }
    
    const listIsLoading = () => {
        return courseListLoadingKeys.includes(CourseLoadingKeys.courses) && courseListLoading
    }

    if (!isFirstLoadComplete) {
        return <MainLoader />
    }

    return (
        <>
            <BackToTop isLoading={listIsLoading()} type={"course-list"}/>
            <div className="pt-7.5 md:pt-9.5 pb-6 md:pb-12 h-full">
                <div className="container h-full flex-col flex">
                    <div className="flex flex-wrap justify-between items-center gap-5 pb-9 md:pb-9.5">
                        <h1 className="w-full md:w-auto text-lg md:text-xl leading-38 font-semibold tracking-1 font-base">
                            Courses
                        </h1>
                        <div className="w-full md:w-auto flex flex-wrap items-center gap-6 sm:gap-4">
                            <CourseSearch
                                value={search}
                                onChange={setSearch}
                                disabled={isReorderingMode || (!arrayHasItems(courses) && !search)}
                                isReorderingMode={isReorderingMode}
                                placeholder={"Search Courses"}
                            />
                            {'admin' === currentUser?.role && (
                            <div className="flex flex-wrap items-center gap-4 flex-col sm:flex-row w-full sm:w-auto">
                                <div className="flex-grow-0 flex-shrink-0 w-full sm:w-auto">
                                    <Btn
                                        onClick={() => setNewCourseModalOpen(true)}
                                        row={true}
                                        disabled={isReorderingMode}
                                        full={true}
                                    >
                                        <div className="w-5 h-5 flex content-center justify-center relative">
                                            <div className={classNames(["bg-white bg-opacity-30 h-full w-full absolute z-10 rounded",
                                                    { 
                                                        "change-background-color-plus": !isReorderingMode
                                                    }
                                                ])}>
                                            </div>
                                            <PlusIcon className="w-3 h-3 mt-1 z-20 fill-white" />
                                        </div>
                                        <span>Add New Course</span>
                                    </Btn>
                                   
                                </div>
                                {!isReorderingMode && (
                                    <div className="flex w-full sm:w-auto">
                                        <Btn onClick={() => enableReordering()} full={true}>
                                            Reorder Courses
                                        </Btn>
                                    </div>
                                )}
                                {isReorderingMode && (
                                    <div className="flex gap-2.5 rounded-sm w-full sm:w-auto">
                                        <div className="flex w-full sm:w-auto">
                                            <Btn onClick={() => saveSortOrder()} full={true}>
                                                Save
                                            </Btn>
                                        </div>
                                        <div className="flex w-full sm:w-auto">
                                            <Btn variant="secondary" outline={true} onClick={() => setIsReorderingMode(false)} full={true}>
                                                Cancel
                                            </Btn>
                                        </div>
                                    </div>
                                )}
                             </div>
                            )}
                        </div>
                    </div>
                        {listIsLoading() && (
                            <div className="flex-auto">
                                <MainLoader />
                            </div>
                        )}
                        {!listIsLoading() && arrayHasItems(courses) && (
                            <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4 pb-9 md:pb-9.5">
                                {renderedCourses.map((course) => (
                                    isReorderingMode ? <div 
                                        key={`course-${course?.id}`}
                                        className="relative group"
                                        draggable
                                        onDragStart={() => setDragedItem(course)}
                                        onDragOver={(e) => {
                                            e.preventDefault();
                                            if (!draggedItem || draggedItem === course) {
                                                return;
                                            }
                                            const currentIndex = renderedCourses.indexOf(draggedItem);
                                            const targetIndex = renderedCourses.indexOf(course);
                                            if (currentIndex !== -1 && targetIndex !== -1) {
                                                const newItems = [...renderedCourses];
                                                newItems.splice(currentIndex, 1);
                                                newItems.splice(targetIndex, 0, draggedItem);
                                                setTemporaryItems(newItems);
                                            }
                                        }}
                                        onDragEnd={() => {
                                            onDragEnd(renderedCourses);
                                            setDragedItem(undefined);
                                            setTemporaryItems(undefined);
                                        }}
                                        >
                                            <div className="cursor-move absolute z-10 flex items-center justify-center w-full h-full opacity-0 hover:opacity-100 transition-opacity duration-300 bg-grey-100 bg-opacity-50 hover:bg-opacity-75 rounded-lg">
                                                <MenuAlt4Icon className="w-5 h-5 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                                            </div>
                                        <CourseCardItem courseItem={course} isAdmin={isAdmin} onClickEdit={() => handleCourseEdit(course)} isReorderingMode={isReorderingMode} />
                                    </div>
                                    :
                                    <CourseCardItem key={`course-${course?.id}`} isAdmin={isAdmin} courseItem={course} onClickEdit={() => handleCourseEdit(course)} isReorderingMode={isReorderingMode} />
                                ))}
                            </div>
                        )}
                    </div>
                </div>

            <FormModal
                open={newCourseModalOpen}
                setOpen={setNewCourseModalOpen}
                Form={CourseForm}
                editingItem={editingCourse}
                additionalFormData={{
                    search: debouncedInputValue
                }}
                onAfterClose={() => setEditingCourse(null)}
                deleteAction={async () => {
                    await dispatch(deleteCourseAsync({ course: editingCourse }))
                }}
            />

        </>
    )
}

export default CourseList
