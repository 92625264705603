import React from 'react'

export const AdvertisingIcon = ({ fill }) => {
  return (
    <svg width="35" height="27" viewBox="0 0 35 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.9062 22.25H4.375V1.46875C4.375 0.864453 3.88555 0.375 3.28125 0.375H1.09375C0.489453 0.375 0 0.864453 0 1.46875V24.4375C0 25.6454 0.97959 26.625 2.1875 26.625H33.9062C34.5105 26.625 35 26.1355 35 25.5312V23.3438C35 22.7395 34.5105 22.25 33.9062 22.25ZM31.7188 2.5625H23.6482C22.1867 2.5625 21.4546 4.32959 22.4882 5.36318L24.703 7.57803L19.6875 12.5942L14.672 7.57871C13.8175 6.72422 12.4325 6.72422 11.5787 7.57871L6.88311 12.2743C6.45586 12.7016 6.45586 13.394 6.88311 13.8213L8.42939 15.3676C8.85664 15.7948 9.54912 15.7948 9.97637 15.3676L13.125 12.2183L18.1405 17.2338C18.995 18.0883 20.38 18.0883 21.2338 17.2338L27.7963 10.6713L30.0111 12.8861C31.0447 13.9197 32.8118 13.1876 32.8118 11.7261V3.65625C32.8125 3.05195 32.323 2.5625 31.7188 2.5625Z"
        className="fill-current"
      />
    </svg>
  )
}
