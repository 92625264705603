import classNames from 'classnames'

const Btn = ({ children, href, target, row, variant, outline, disabled, onClick, full, loading, testId }) => {
  const btnClassNames = classNames([
    'rounded-lg py-2.25 border border-solid text-sm leading-20 font-medium font-title tracking-0.5 transition-colors transition-all',
    'focus:outline-none focus:ring-2 focus:ring-offset-2',
    'change-plus-icon',
    {
      'inline-block px-2.5 2xl:px-5 text-center': !row,
      'inline-flex items-center justify-center px-3 gap-2': row,
      'bg-secondary border-secondary focus:ring-secondary text-white ': !variant && !outline,
      'bg-secondary bg-opacity-10 border-secondary border-opacity-10 focus:ring-secondary text-primary-800':
        !variant && outline,
      'bg-white border-opacity-7 border-dark text-dark focus:ring-dark':
        variant === 'secondary' && outline,
      'cursor-pointer': !disabled,
      'cursor-not-allowed': disabled,
      'w-full max-w-full': full,
      'text-opacity-50': disabled,
      'hover:bg-secondary-100 hover:text-primary': !disabled && !variant && !outline,
      'hover:border-primary-800': (disabled && !variant && !outline) || (!variant && outline),
      'hover:bg-dark hover:bg-grey-200 hover:border-opacity-100':
        !disabled && variant === 'secondary' && outline
    }
  ])

  if (href) {
    return (
      <a
        href={href}
        target={target}
        rel={target ? 'noreferrer' : undefined}
        className={btnClassNames}
        data-testid={testId}
      >
        {loading ? <div>Loading...</div> : children}
      </a>
    )
  }

  return (
    <button type="button" className={btnClassNames} disabled={disabled} onClick={onClick} data-testid={testId}>
      {loading ? <div>Loading...</div> : children}
    </button>
  )
}

export default Btn
