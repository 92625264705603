import React from 'react'

const FormFieldTooltip = ({ dataFor, testId }) => {
  return (
    <span className="ml-2 flex items-center space-x-1 cursor-pointer" data-tip data-for={dataFor} data-testid={testId}>
      <img src="/images/question.svg" alt="content tooltip" width="17" height="15" />
      <span className="text-xs text-gray-400">Learn more</span>
    </span>
  )
}

export default FormFieldTooltip
