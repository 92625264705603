import React from 'react'

export const PlanningIcon = () => {
  return (
    <svg width="30" height="27" viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.375 1.03547C24.375 0.505726 23.9555 0.0766602 23.4375 0.0766602H19.6875C19.1695 0.0766602 18.75 0.505726 18.75 1.03547V3.91189H24.375V1.03547ZM3.74473 7.74652C3.59766 13.3705 0.202734 14.5918 0 22.3689V25.0056C0 26.0645 0.839648 26.9233 1.875 26.9233H7.5C8.53535 26.9233 9.375 26.0645 9.375 25.0056V15.4176H11.25V5.8295H5.61562C4.58262 5.8295 3.77285 6.69063 3.74473 7.74652ZM26.2553 7.74652C26.2271 6.69063 25.4174 5.8295 24.3844 5.8295H18.75V15.4176H20.625V25.0056C20.625 26.0645 21.4646 26.9233 22.5 26.9233H28.125C29.1604 26.9233 30 26.0645 30 25.0056V22.3689C29.7973 14.5918 26.4023 13.3705 26.2553 7.74652ZM10.3125 0.0766602H6.5625C6.04453 0.0766602 5.625 0.505726 5.625 1.03547V3.91189H11.25V1.03547C11.25 0.505726 10.8305 0.0766602 10.3125 0.0766602ZM13.125 15.4176H16.875V5.8295H13.125V15.4176Z"
        className="fill-current"
      />
    </svg>
  )
}
