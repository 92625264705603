import React from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import FormFieldTooltip from '../components/general/FormFieldTooltip'

const FormField = ({
  label,
  field,
  type = 'text',
  error,
  onChange,
  required = true,
  state,
  tooltip = null,
  disabled = false,
  ...rest
}) => {
  return (
    <div>
      <label
        htmlFor={field}
        className={`${
          tooltip ? 'flex justify-between' : 'block'
        } text-sm font-medium text-gray-700`}
      >
        {label}
        {tooltip && <FormFieldTooltip dataFor={tooltip} />}
      </label>
      <div className="mt-1 relative -md shadow-sm">
        {'textarea' === type ? (
          <textarea
            id={field}
            name={field}
            type={type}
            className={`appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-sm ${
              error
                ? 'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                : 'border-gray-300 focus:ring-purple-450 focus:border-purple-450 placeholder-gray-400 '
            } ${disabled && 'text-gray-400 bg-gray-100'}`}
            value={state[field] || ''}
            onChange={onChange}
            required={required}
            disabled={disabled}
            {...rest}
          />
        ) : (
          <input
            id={field}
            name={field}
            type={type}
            className={`appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-sm ${
              error
                ? 'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                : 'border-gray-300 focus:ring-purple-450 focus:border-purple-450 placeholder-gray-400 '
            } ${disabled && 'text-gray-400 bg-gray-100'}`}
            value={state[field] || ''}
            onChange={onChange}
            required={required}
            disabled={disabled}
            {...rest}
          />
        )}

        {error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        )}
      </div>
      {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
    </div>
  )
}

export default FormField
