import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PrimaryButton from '../../components/general/PrimaryButton'
import DeleteButton from '../../components/general/DeleteButton'
import SecondaryButton from '../../components/general/SecondaryButton'
import FormField from '../../utils/FormField'
import { clearError, upsertModuleAsync } from './courseSlice'
import Select from 'react-select'

function Checkbox({ value, onChange, label, name }) {
  return (
    <div className="flex items-center h-5">
      <input
        id={name}
        name={name}
        type="checkbox"
        className="focus:ring-purple-450 h-4 w-4 text-purple-450 border-gray-300 rounded"
        checked={value || false} // default to false to avoid warning in case undefined was passed
        onChange={onChange}
      />
      <div className="ml-3 text-sm">
        <label htmlFor={name} className="font-medium text-gray-700">
          {label}
        </label>
      </div>
    </div>
  )
}

const ModuleForm = ({ 
  setState, 
  setOpen, 
  editingItem,
  courseId,
  courseSlug,
  courseVisibilityPremium,
  courseVisibilityBasic,
  search
}) => {
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState({ 
    visibleToBasic: courseVisibilityBasic, 
    visibleToPremium: courseVisibilityPremium 
  })
  const { error } = useSelector((state) => state.course)
  const { tags } = useSelector((state) => state.tag)

  useEffect(() => {
    if (editingItem) setFormValues({ ...editingItem })
    else setFormValues({ 
        position: 0, 
        visibleToBasic: courseVisibilityBasic, 
        visibleToPremium: courseVisibilityPremium 
      })
  }, [editingItem]);

  const onChange = (event) => {
    event.preventDefault()
    let { name, value, checked } = event.target
    const isOneOfCheckboxInputs = ['visibleToBasic', 'visibleToPremium'].includes(name)
    if (isOneOfCheckboxInputs) value = checked // boolean instead of string
    error && error[name] && dispatch(clearError({ field: name }))
    setFormValues((prevState) => ({ ...prevState, [name]: value }))
  }

  return (
    <div>
      <h3 className="text-2xl">
        {formValues?.id ? 'Edit module: ' + formValues.title : 'Add a new module'}
      </h3>
      <form
        onSubmit={async (event) => {
          event.preventDefault()
          const currentValues = formValues;
          if (courseId) {
            currentValues.CourseId = courseId
          }
          dispatch(upsertModuleAsync({ module: currentValues, courseSlug, search }))
          setOpen(false)
        }}
      >
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <div className="mt-1">
              <FormField
                label="Module title"
                field="title"
                error={error && error['title']}
                onChange={onChange}
                state={formValues}
              />
            </div>
          </div>
          {/* TODO: will be get from course visibility ( need explain for this ) */}
          {/* <div className="sm:col-span-3 pt-6">
            <Checkbox
              value={formValues.visibleToBasic}
              onChange={onChange}
              label="Visible to basic users"
              name="visibleToBasic"
            />
            <Checkbox
              value={formValues.visibleToPremium}
              onChange={onChange}
              label="Visible to premium users"
              name="visibleToPremium"
            />
          </div> */}
          <div className="sm:col-span-3">
            <div className="mt-1">
              <label htmlFor="tags" className="block text-sm font-medium text-gray-700">
                Required Tags
              </label>
              <div className="mt-1">
                <Select
                  id="tags"
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      padding: '2px',
                    }),
                    
                  }}
                  isMulti={true}
                  value={formValues?.Tags?.map((tag) => ({
                    value: tag.id,
                    label: tag.name
                  }))}
                  onChange={(Tags) =>
                    setFormValues((prevState) => ({
                      ...prevState,
                      Tags: Tags.map((tag) => ({
                        id: tag.value,
                        name: tag.label
                      }))
                    }))
                  }
                  options={tags.map((tag) => ({
                    value: tag.id,
                    label: tag.name
                  }))}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-24 py-3 flex flex-row flex-wrap gap-2 sm:gap-4 justify-between">
          <div className="flex justify-start w-full sm:w-auto">
            {editingItem?.id && (
              <DeleteButton label="Delete" extraClasses="uppercase w-full sm:w-auto" onClick={() => setState('delete')} />
            )}
          </div>
          <div className="flex gap-2 sm:gap-4 w-full sm:w-auto justify-end">
            <PrimaryButton extraClasses="uppercase w-full sm:w-auto" label="Save" type="submit" />
            <SecondaryButton
              extraClasses="uppercase w-full sm:w-auto"
              label="Cancel"
              onClick={() => setOpen(false)}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default ModuleForm
