import { ArrowNarrowLeftIcon, PencilIcon } from '@heroicons/react/outline'
import React, { useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import MetricItem from './MetricItem'

const ViewMetrics = ({ product, closeHandler, setOpen, setEditingItem }) => {
  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])
  return (
    <div>
      <button
        className="flex items-center border-2 border-gray-400 px-4 py-2 rounded-lg mb-8"
        onClick={closeHandler}
      >
        <ArrowNarrowLeftIcon className="w-4 h-4 mr-2" />
        back to products
      </button>

      <div key={product.id} className="flex flex-col sm:flex-row sm:space-x-4 mb-10">
        <div className="w-full sm:w-3/5 rounded-md border py-6 px-6">
          <div className="flex justify-between items-center">
            <h1 className="text-4xl">{product.title}</h1>
            <button
              onClick={() => {
                setEditingItem(product)
                setOpen(true)
              }}
              className="inline-flex px-4 py-2 rounded-md items-center text-purple-450 hover:bg-gray-100 transition-colors"
            >
              <PencilIcon className="h-5 w-5 mr-2" />
              edit
            </button>
          </div>
          <p className="mt-6">{product.description}</p>
        </div>
        <div className="w-full sm:w-2/5 rounded-md border flex flex-col items-center py-4 px-2 bg-gray-300">
          <div className="flex flex-col xl:flex-row">
            <MetricItem
              itemName="Potential Profit"
              itemValue={product.potentialProfit}
              dollar={true}
            />
            <div className="xl:h-12 border-b xl:border-r border-black xl:mt-12"></div>
            <MetricItem
              itemName="Profit Margin"
              itemValue={product.profitMargin}
              percentage={true}
              tooltip="profit-margin-tooltip"
            />
          </div>
          <div className="flex flex-col xl:flex-row">
            <MetricItem itemName="Total Cost" itemValue={product.totalCost} dollar={true} />
            <div className="xl:h-12 border-b xl:border-r border-black xl:mt-12"></div>
            <MetricItem
              itemName="Total + Shipping Cost"
              itemValue={product.totalPlusShippingCost}
              dollar={true}
            />
          </div>
          <div className="flex flex-col xl:flex-row">
            <MetricItem
              itemName="Total Cost Per Unit (+ Shipping)"
              itemValue={product.totalCostPerUnit}
              dollar={true}
            />
            <div className="xl:h-12 border-b xl:border-r border-black xl:mt-12"></div>
            <MetricItem itemName="Amazon Net" itemValue={product.amazonNet} dollar={true} />
          </div>
          <div className="flex">
            <MetricItem
              itemName="Net Profit per Unit"
              itemValue={product.netProfitPerUnit}
              dollar={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewMetrics
