import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { toast } from "react-toastify"
import { removeLoadingKey } from "../../helpers"

export const CourseLoadingKeys = {
  courses: "ALL_COURSES"
}

export const upsertCourseAsync = createAsyncThunk(
  'course/upsert',
  async ({ course, search }, { rejectWithValue, dispatch }) => {
    try {
      await axios.post(`/api/course/upsert`, course)
      dispatch(readManyCoursesAsync({ search }));
    } catch (error) {
      if ('string' === typeof error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data.errors)
      }
    }
  }
)

export const readManyCoursesAsync = createAsyncThunk(
  'course/read-many',
  async ({ search }, { rejectWithValue, dispatch }) => {
    try {
      const params = { search }
      const { data } = await axios.get(`/api/course/read-many`, { params });
      return data;
    } catch (error) {
      if ('string' === typeof error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data.errors)
      }
    }
  }
)

export const getLessonRedirectAsync = createAsyncThunk(
  'course/lesson-redirect',
  async ({ lessonId }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.get(`/api/course/lesson-redirect/${lessonId}`);
      return data;
    } catch (error) {
      if ('string' === typeof error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data.errors)
      }
    }
  }
)

export const getUserAccessToCurrentCourse = createAsyncThunk(
  'course/accessToCourse',
  async ({ slug }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/api/course/access`, { params: { slug } });
      return data;
    } catch (error) {
      if ('string' === typeof error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data.errors || error.response.data.error)
      }
    }
  }
)

export const deleteCourseAsync = createAsyncThunk(
  'course/delete',
  async ({ course: { id } }, { rejectWithValue, dispatch }) => {
    try {
      await axios.delete(`/api/course/${id}`);
      dispatch(readManyCoursesAsync({}));
    } catch (error) {
      if ('string' === typeof error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data.errors)
      }
    }
  }
)

export const getCourseContentAsync = createAsyncThunk(
  'course/details',
  async ({ slug, search }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/api/course/${slug}`, { params: { search } });
      return data;
    } catch (error) {
      if ('string' === typeof error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data.errors || error.response.data.error)
      }
    }
  }
)

export const reorderModulesAndLessons = createAsyncThunk(
  'course/reorder',
  async ({ modules, slug, courseId }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.post(`/api/course/reorder`, { modules, courseId });
      if (data?.success) {
        dispatch(getCourseContentAsync({ slug }))
        toast.success('Reorder is finished correctly.')
      } else {
        toast.error('Something went wrong.')
        throw new Error('Something went wrong.')
      }
    } catch (error) {
      if ('string' === typeof error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data.errors)
      }
    }
  }
)

export const reorderCoursesPositions = createAsyncThunk(
  'course/reorder-courses',
  async ({ courses, search, itemsPerPage }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.post('/api/course/reorder-courses', { courses });
      if (data?.success) {
        dispatch(readManyCoursesAsync({ search, itemsPerPage }))
        toast.success('Reorder is finished correctly.')
      } else {
        toast.error('Something went wrong.')
        throw new Error('Something went wrong.')
      }
    } catch (error) {
      if ('string' === typeof error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data.errors)
      }
    }
  }
)

const initialState = {
  loading: true,
  loadingKeys: [],
  isFirstLoadComplete: false,
  redirectUrl: null,
  redirectUrlLoadComplete: false,
  hasAccess: false,
  hasAccessLoadComplete: false,
  courseDetailsLoadComplete: false,
  error: null,
  courses: [],
  courseDetails: null
}

export const coursesListSlice = createSlice({
  name: 'courseList',
  initialState,
  reducers: {
    clearError: (state, action) => {
      if (state.error) state.error[action.payload.field] = null
    },
    clearErrors: (state) => {
      state.error = null
      state.generalError = null
    },
    setError: (state, action) => {
      state.error[action.payload.field] = action.payload.error
    },
    cleanUpCourseData: (state) => {
      state.error = null
      state.isFirstLoadComplete = false
      state.hasAccess = false
      state.hasAccessLoadComplete = false
      state.courseDetailsLoadComplete = false
      state.courseDetails = null
    }
    // setActiveLesson: (state, action) => {
    //   state.activeLesson = action.payload.lesson
    // },
    // setActiveModule: (state, action) => {
    //   state.activeModule = action.payload.module
    // }
  },
  extraReducers: (builder) => {
    builder

      .addCase(readManyCoursesAsync.pending, (state) => {
        state.loading = true
        state.loadingKeys = [...state.loadingKeys, CourseLoadingKeys.courses]
      })
      .addCase(readManyCoursesAsync.fulfilled, (state, action) => {
        state.loading = false
        state.isFirstLoadComplete = true
        state.courses = action.payload ? action.payload.courses : []
        state.loadingKeys = removeLoadingKey(CourseLoadingKeys.courses, state.loadingKeys)
      })
      .addCase(readManyCoursesAsync.rejected, (state, action) => {
        state.loading = false
        state.loadingKeys = removeLoadingKey(CourseLoadingKeys.courses, state.loadingKeys)
      })
      .addCase(getUserAccessToCurrentCourse.pending, (state) => {
        state.loading = true
        state.hasAccessLoadComplete = false
      })

      .addCase(getUserAccessToCurrentCourse.fulfilled, (state, action) => {
        state.loading = false;
        state.hasAccess = action.payload?.hasAccess;
        state.hasAccessLoadComplete = true;
      })
      .addCase(getUserAccessToCurrentCourse.rejected, (state, action) => {
        state.loading = false;
        state.hasAccess = action.payload?.hasAccess;
        state.hasAccessLoadComplete = true;
        state.error = action.payload;
      })
      .addCase(getLessonRedirectAsync.pending, (state) => {
        state.loading = true
        state.redirectUrl = null;
        state.redirectUrlLoadComplete = false;
      })
      .addCase(getLessonRedirectAsync.fulfilled, (state, action) => {
        state.loading = false
        state.redirectUrl = action.payload?.url;
        state.redirectUrlLoadComplete = true;
      })
      .addCase(getLessonRedirectAsync.rejected, (state, action) => {
        state.loading = false
        state.redirectUrl = null;
        state.redirectUrlLoadComplete = true;
        state.error = action.error
      })
      .addCase(upsertCourseAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(upsertCourseAsync.fulfilled, (state, action) => {
        state.loading = false
        toast.success('Course saved successfully')
      })
      .addCase(upsertCourseAsync.rejected, (state, action) => {
        state.loading = false
        state.error = action.error
      })
      .addCase(deleteCourseAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteCourseAsync.fulfilled, (state, action) => {
        state.loading = false
        toast.success('Course deleted successfully')
      })
      .addCase(deleteCourseAsync.rejected, (state, action) => {
        state.loading = false
        state.error = action.error
      })
      .addCase(getCourseContentAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(getCourseContentAsync.fulfilled, (state, action) => {
        state.loading = false
        state.courseDetails = action?.payload ? action.payload : null
        state.courseDetailsLoadComplete = true;
      })
      .addCase(getCourseContentAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.courseDetailsLoadComplete = true;
        state.courseDetails = null;
      })
      .addCase(reorderModulesAndLessons.pending, (state) => {
        state.loading = true
      })
      .addCase(reorderCoursesPositions.pending, (state) => {
        state.loading = true
      })
  }
})

export const { clearError, clearErrors, setError, cleanUpCourseData } =
  coursesListSlice.actions

export default coursesListSlice.reducer
