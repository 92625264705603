import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { getCourseContentAsync } from '../courses-list/coursesListSlice';

const initialState = {
  loading: true,
  isFirstLoadComplete: false,
  error: null,
  modules: [],
  lessons: [],
  lessonOptions: [],
  completedLessons: [],
  activeModule: null,
  activeLesson: null
}

export const useCourseModules = () => {
  const dispatch = useDispatch()
  const { modules, isFirstLoadComplete } = useSelector((state) => state.course)

  // if modules were not already loaded, load them
  useEffect(() => {
    if (!isFirstLoadComplete) dispatch(readManyModulesAsync())
  }, [dispatch, isFirstLoadComplete])

  function refreshModules() {
    dispatch(readManyModulesAsync())
  }

  return { modules, refreshModules }
}

export const readManyModulesAsync = createAsyncThunk('module/readMany', async () => {
  const { data } = await axios.get('/api/module/read-many')
  return { modules: data.modules }
})

export const upsertModuleAsync = createAsyncThunk(
  'module/upsert',
  async ({ module }, { rejectWithValue, dispatch }) => {
    try {
      await axios.post(`/api/module/upsert`, module)
      dispatch(readManyModulesAsync())
      // dispatch(readManyLessonsAsync());
      dispatch(readLessonOptionsAsync())
    } catch (error) {
      if ('string' === typeof error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data.errors)
      }
    }
  }
)

export const deleteModuleAsync = createAsyncThunk(
  'module/delete',
  async ({ module, slug, search }, { dispatch }) => {
    await axios.delete(`/api/module/${module.id}`)
    await dispatch(readManyModulesAsync())
    await dispatch(readLessonOptionsAsync())

    if (slug) {
      await dispatch(getCourseContentAsync({ slug, search }))
    }
    
    return { id: module.id }
  }
)

export const readManyLessonsAsync = createAsyncThunk('lesson/readMany', async () => {
  const { data } = await axios.get('/api/lesson/read-many')
  return { lessons: data.lessons }
})

export const readLessonOptionsAsync = createAsyncThunk('lesson/readOptions', async () => {
  const { data } = await axios.get('/api/lesson/read-options')
  return { lessonOptions: data.lessons }
})

export const upsertLessonAsync = createAsyncThunk(
  'lesson/upsert',
  async ({ lesson }, { rejectWithValue, dispatch }) => {
    try {
      await axios.post(`/api/lesson/upsert`, lesson)
      dispatch(readManyModulesAsync())
      // dispatch(readManyLessonsAsync());
      dispatch(readLessonOptionsAsync())
      dispatch(setActiveLesson({ lesson }))
      //dispatch(getCompletedLessonsAsync())
      //return { lesson: data.lesson }
    } catch (error) {
      if ('string' === typeof error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data.errors)
      }
    }
  }
)

export const deleteLessonAsync = createAsyncThunk(
  '/lesson/delete',
  async ({ lesson, slug, search }, { dispatch }) => {
    await axios.delete(`/api/lesson/${lesson.id}`)
    await dispatch(readManyModulesAsync())
    await dispatch(readLessonOptionsAsync())
    
    if (slug) {
      await dispatch(getCourseContentAsync({ slug, search }))
    }

    return { id: lesson.id }
  }
)

export const getCompletedLessonsAsync = createAsyncThunk('lesson/completed', async () => {
  const { data } = await axios.get('/api/lesson/completed')
  return { lessons: data.lessons }
})

export const reorderModulesAsync = createAsyncThunk(
  'module/reorder',
  async ({ modules }, { dispatch }) => {
    try {
      // Fix error 413 payload too large
      const idPosition = modules.map((module) => {
        return { id: module.id, position: module.position }
      })
      const res = await axios.post('/api/module/reorder', { modules: idPosition })
      if (res.data?.success) {
        dispatch(readManyModulesAsync())
        toast.success('Module reordered successfully')
      } else {
        throw new Error('Something went wrong')
      }
    } catch (error) {
      toast.error(error)
    }
  }
)

export const markLessonAsCompletedAsync = createAsyncThunk(
  'lesson/mark-as-completed',
  async ({ lessonId, courseSlug, search }, { dispatch }) => {
    await axios.put('/api/lesson/mark-as-completed', { lessonId })
    if (courseSlug) {
      await dispatch(getCourseContentAsync({ slug: courseSlug, search }))
    }
  }
)

export const markLessonAsUncompletedAsync = createAsyncThunk(
  'lesson/mark-as-uncompleted',
  async ({ lessonId, courseSlug, search }, { dispatch }) => {
    await axios.put('/api/lesson/mark-as-uncompleted', { lessonId })
    if (courseSlug) {
      await dispatch(getCourseContentAsync({ slug: courseSlug, search }))
    }
  }
)

export const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    clearError: (state, action) => {
      if (state.error) state.error[action.payload.field] = null
    },
    clearErrors: (state) => {
      state.error = null
      state.generalError = null
    },
    setError: (state, action) => {
      state.error[action.payload.field] = action.payload.error
    },
    setActiveLesson: (state, action) => {
      state.activeLesson = action.payload.lesson
    },
    setActiveModule: (state, action) => {
      state.activeModule = action.payload.module
    }
  },
  extraReducers: (builder) => {
    builder

      .addCase(readManyModulesAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(readManyModulesAsync.fulfilled, (state, action) => {
        state.loading = false
        state.isFirstLoadComplete = true
        state.modules = action.payload.modules
      })
      .addCase(readManyModulesAsync.rejected, (state, action) => {
        state.loading = false
      })
      .addCase(upsertModuleAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(upsertModuleAsync.fulfilled, (state, action) => {
        state.loading = false
        toast.success('Module saved successfully')
      })
      .addCase(upsertModuleAsync.rejected, (state, action) => {
        state.loading = false
        state.error = action.error
      })
      .addCase(deleteModuleAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteModuleAsync.fulfilled, (state, action) => {
        state.loading = false
        state.modules = state.modules.filter((module) => module.id !== action.payload.id)
        toast.success('Module deleted successfully')
      })
      .addCase(deleteModuleAsync.rejected, (state, action) => {
        state.loading = false
        state.error = action.error
      })
      .addCase(readManyLessonsAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(readManyLessonsAsync.fulfilled, (state, action) => {
        state.loading = false
        state.lessons = action.payload.lessons
      })
      .addCase(readManyLessonsAsync.rejected, (state, action) => {
        state.loading = false
      })

      .addCase(markLessonAsCompletedAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(markLessonAsCompletedAsync.fulfilled, (state, action) => {
        state.loading = false
        toast.success('Marked as completed successfully.')
      })
      .addCase(markLessonAsCompletedAsync.rejected, (state, action) => {
        state.loading = false
        toast.error('Could not mark as completed. Reload the page and try again.')
      })

      .addCase(markLessonAsUncompletedAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(markLessonAsUncompletedAsync.fulfilled, (state, action) => {
        state.loading = false
        toast.success('Marked as uncompleted successfully.')
      })
      .addCase(markLessonAsUncompletedAsync.rejected, (state, action) => {
        state.loading = false
        toast.error('Could not mark as uncompleted. Reload the page and try again.')
      })

      .addCase(upsertLessonAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(upsertLessonAsync.fulfilled, (state, action) => {
        state.loading = false
        toast.success('Lesson saved successfully')
      })
      .addCase(upsertLessonAsync.rejected, (state, action) => {
        state.loading = false
        state.error = action.error
      })
      .addCase(deleteLessonAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteLessonAsync.fulfilled, (state, action) => {
        state.loading = false
        toast.success('Lesson deleted successfully')
      })
      .addCase(deleteLessonAsync.rejected, (state, action) => {
        state.loading = false
        state.error = action.error
      })
      .addCase(getCompletedLessonsAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(getCompletedLessonsAsync.fulfilled, (state, action) => {
        state.loading = false
        state.completedLessons = action.payload.lessons
      })
      .addCase(getCompletedLessonsAsync.rejected, (state, action) => {
        state.loading = false
      })
      .addCase(readLessonOptionsAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(readLessonOptionsAsync.fulfilled, (state, action) => {
        state.loading = false
        state.lessonOptions = action.payload.lessonOptions
      })
      .addCase(readLessonOptionsAsync.rejected, (state, action) => {
        state.loading = false
      })
  }
})
export const { clearError, clearErrors, setError, setActiveLesson, setActiveModule } =
  courseSlice.actions
export default courseSlice.reducer
