import { useEffect, useState } from "react";

export const arrayHasItems = (array) => {
    return array && array instanceof Array && array.length > 0;
}

export function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
        () => {
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay],
    );
    return debouncedValue;
}

export function useBackToTop(isLoading, type) {
    const getWatcher = () => {
        switch (type) {
            case "course-list": {
                const cards = document.getElementsByClassName('course-item-card');
                return cards[0]
            }
            default: {
                return null
            }
        }
    }
    useEffect(() => {
        if (typeof window !== "undefined" && !isLoading && type) {
            const scrollButton = document.getElementById('scroll-to-top-button');
            const main = document.getElementById('main');
            let watcher = null;
            if (type === "course-list") {
                watcher = getWatcher();
            }
            if (watcher && main && scrollButton) {
                const handleScroll = () => {
                    const clientCardHeight = watcher.clientHeight;
                    const scrollTop = main.scrollTop;
                    const cardOffsetTop = watcher.offsetTop;

                    if (scrollTop >= clientCardHeight + cardOffsetTop) {
                        scrollButton.classList.add('block');
                        scrollButton.classList.remove('hidden');
                    } else {
                        scrollButton.classList.add('hidden');
                        scrollButton.classList.remove('block');
                    }
                };
                main.addEventListener('scroll', handleScroll);
                scrollButton.addEventListener('click', () => {
                    main.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                });
                return () => {
                    main.removeEventListener('scroll', handleScroll);
                    scrollButton.removeEventListener('click', handleScroll);
                };
            }
        }
    }, [type, isLoading]);
}

export const arrayMove = (array, from, to) => {
    const slicedArray = array.slice()
    slicedArray.splice(to < 0 ? array.length + to : to, 0, slicedArray.splice(from, 1)[0])
    return slicedArray
}

export const getFilteredCoursesByRole = (
    courses,
    role,
) => {
    return courses.filter((course) => hasAccessToCourse(course, role));
}

export const hasAccessToCourse = (course, role) => {
    if (role === 'admin') return true
    if ((role === 'coach' || role === 'premium') && course.visibleToPremium) return true
    if (role === 'basic' && course.visibleToBasic) return true
    return false
}

export const removeLoadingKey = (loadingKey, keys) => {
    if (!arrayHasItems(keys)) return [];
    const currentKeys = [...keys].filter((key) => key !== loadingKey);
    return currentKeys;
}

export const getDetailInformationMessage = (
    itemInformation,
    type = "default"
) => {
    let message = ""
    if (!itemInformation) return message
    const tags = itemInformation?.Tags;
    const description = itemInformation?.description;
    if (arrayHasItems(tags)) {
        message = message + `Tags: ${tags.map((tag) => tag.name).join(", ")}`
    }
    if (type === "course-title" && description) {
        const marginDescription = arrayHasItems(tags) ? "mt-1" : "";
        message = message + `<div class="${marginDescription}">Description: ${description}</div>`
    }
    return message
}