import React from 'react'
import FormFieldTooltip from '../../components/general/FormFieldTooltip'

const MetricItem = ({ itemName, itemValue, tooltip, percentage, dollar }) => {
  const chopIfDecimnal = (n) => (n % 1 !== 0 ? Number(n).toFixed(2) : n || 0)

  return (
    <div className="flex flex-col items-center p-4 xl:p-8 xl:pr-10 xl:pl-10 w-48">
      <span className={`${!itemValue ? 'text-gray-400' : ''} text-4xl font-bold`}>
        {dollar
          ? itemValue >= 0
            ? '$' + chopIfDecimnal(itemValue)
            : '-$' + Math.abs(itemValue)
          : null}

        {percentage ? (chopIfDecimnal(itemValue) || 0) + '%' : null}
      </span>
      {tooltip ? (
        <span
          className={`${
            !itemValue ? 'text-gray-400' : ''
          } text-md mt-2 flex flex-col space-y-2 text-centeer`}
        >
          <span>{itemName}</span>
          <FormFieldTooltip dataFor={tooltip} />
        </span>
      ) : (
        <span className={`${!itemValue ? 'text-gray-400' : ''} text-md mt-2 text-center`}>
          {itemName}
        </span>
      )}
    </div>
  )
}

export default MetricItem
