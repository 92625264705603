import React, { useState, useEffect } from 'react'
import PrimaryButton from '../../components/general/PrimaryButton'
import { PlusCircleIcon, SpeakerphoneIcon, TruckIcon, ViewListIcon } from '@heroicons/react/outline'
import ProductTab from './tabs/ProductTab'
import PromotionTab from './tabs/PromotionTab'
import AdvertisingTab from './tabs/AdvertisingTab'
import PlanningTab from './tabs/PlanningTab'
import AllTab from './tabs/AllTab'
import { AdvertisingIcon } from './icons/AdvertisingIcon'
import { PlanningIcon } from './icons/PlanningIcon'
import { useDispatch, useSelector } from 'react-redux'
import { deleteProductAsync, ProductLoadingKey, readManyProductsAsync } from './powerProfitToolSlice'
import FormModal from '../../components/general/FormModal'
import ProductForm from './ProductForm'
import ReactTooltip from 'react-tooltip'
import ViewMetrics from './ViewMetrics'
import PromotionForm from './PromotionForm'
import AdvertisingForm from './AdvertisingForm'
import PlanningForm from './PlanningForm'
import { MainLoader } from '../../components/Loaders/MainLoader'

const PowerProfitTool = () => {
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState('Product')
  const { 
    products, 
    loading: productsLoading, 
    loadingKeys: loadingProductKeys 
  } = useSelector((state) => state.powerProfitTool)
  const { currentUser } = useSelector((state) => state.auth)
  const isAdmin = currentUser?.role === 'admin'
  const isCoach = currentUser?.role === 'coach'
  const isPremium = currentUser?.role === 'premium'
  const isBasic = currentUser?.role === 'basic'

  // modal
  const [productModalOpen, setProductModalOpen] = useState(false)
  const [editingProduct, setEditingProduct] = useState({})
  const [promotionModalOpen, setPromotionModalOpen] = useState(false)
  const [advertisingModalOpen, setAdvertisingModalOpen] = useState(false)
  const [planningModalOpen, setPlanningModalOpen] = useState(false)

  useEffect(() => {
    dispatch(readManyProductsAsync())
    // eslint-disable-next-line
  }, [])

  const [metricsViewProductId, setMetricsViewProductId] = useState(null)
  const closeMetricsView = () => setMetricsViewProductId(null)
  const productInMetricsView = products?.find((p) => p.id === metricsViewProductId)

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [metricsViewProductId, products, activeTab])

  return (
    <>
      <div className="h-screen py-6 flex flex-col">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900 mb-4">Power Profit Tool</h1>
          <p>
            Earnings Disclaimer: The profitability calculator provided in this course is designed to help you estimate potential earnings. 
            However, the numbers generated are estimates only and do not guarantee your actual results. 
            Individual profitability may vary, as unexpected factors and changes in market conditions can impact outcomes. 
            Success depends on various factors, including effort, experience, and adaptability.
          </p>
          <div className="flex justify-between items-center mt-4 gap-4 flex-wrap">
            <h2 className="text-2xl font-semibold text-gray-900">{activeTab}</h2>
            <div className="sm:w-48">
              <PrimaryButton
                onClick={() => setProductModalOpen(true)}
                label={
                  <span className="flex items-center">
                    <PlusCircleIcon className="h-6 w-6 mr-2" /> Add a Product
                  </span>
                }
                extraClasses="uppercase py-3"
              />
            </div>
          </div>
        </div>
        <div className="max-w-6xl mx-auto px-4 sm:px-6 md:px-8 pt-8 md:flex md:flex-col md:flex-1 max-w-full w-full md:w-auto">
          <div className="py-4">
            <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row justify-between md:justify-center pb-12 md:flex-wrap xl:gap-10 md:gap-4">
              <button
                className={`${
                  'Product' === activeTab
                    ? 'text-white bg-purple-450 '
                    : 'text-purple-450 hover:text-white hover:bg-purple-450 '
                } transition-colors px-14 py-4 border shadow-lg rounded-md flex flex-col justify-center items-center`}
                onClick={() => setActiveTab('Product')}
              >
                <TruckIcon className="h-10 w-10" />
                Product
              </button>

              <button
                className={`${
                  'Promotion' === activeTab
                    ? 'text-white bg-purple-450 '
                    : 'text-purple-450 hover:text-white hover:bg-purple-450 '
                }  ${
                  isBasic ? 'opacity-50 cursor-not-allowed' : ''
                } transition-colors px-14 py-4 border shadow-lg rounded-md flex flex-col justify-center items-center`}
                onClick={() => setActiveTab('Promotion')}
                disabled={isBasic}
              >
                <SpeakerphoneIcon className="h-10 w-10" />
                Promotion
              </button>

              <button
                className={`${
                  'Advertising' === activeTab
                    ? 'text-white bg-purple-450 '
                    : 'text-purple-450 hover:text-white hover:bg-purple-450 '
                } ${
                  isBasic ? 'opacity-50 cursor-not-allowed' : ''
                } transition-colors px-14 py-4 border shadow-lg rounded-md flex flex-col justify-center items-center`}
                onClick={() => setActiveTab('Advertising')}
                disabled={isBasic}
              >
                <AdvertisingIcon className="" />
                <span className="pt-1">Advertising</span>
              </button>

              <button
                className={`${
                  'Planning' === activeTab
                    ? 'text-white bg-purple-450 '
                    : 'text-purple-450 hover:text-white hover:bg-purple-450 '
                } ${
                  isBasic ? 'opacity-50 cursor-not-allowed' : ''
                } transition-colors px-14 py-4 border shadow-lg rounded-md flex flex-col justify-center items-center`}
                onClick={() => setActiveTab('Planning')}
                disabled={isBasic}
              >
                <PlanningIcon className="" />
                <span className="pt-1">Planning</span>
              </button>

              <button
                className={`${
                  'View All' === activeTab
                    ? 'text-white bg-purple-450 '
                    : 'text-purple-450 hover:text-white hover:bg-purple-450 '
                } ${
                  isBasic ? 'opacity-50 cursor-not-allowed' : ''
                } transition-colors px-14 py-4 border shadow-lg rounded-md flex flex-col justify-center items-center`}
                onClick={() => setActiveTab('View All')}
                disabled={isBasic}
              >
                <ViewListIcon className="h-10 w-10" />
                View All
              </button>
            </div>
            {(productsLoading && loadingProductKeys.includes(ProductLoadingKey.products)) ? (
              <MainLoader />
            ) : (
              metricsViewProductId && 'Product' === activeTab ? (
                <ViewMetrics
                  product={productInMetricsView}
                  closeHandler={closeMetricsView}
                  setOpen={setProductModalOpen}
                  setEditingItem={setEditingProduct}
                />
              ) : (
                <>
                  {0 === products?.length ? (
                    <div className="flex flex-col items-center justify-center text-gray-400 space-y-6">
                      <PlusCircleIcon className="h-36 w-36" />
                      <h1 className="text-center text-xl">
                        You currently do not have any
                        <br />
                        existing products
                      </h1>
  
                      <div className="max-w-xl">
                        <PrimaryButton
                          onClick={() => setProductModalOpen(true)}
                          label={
                            <span className="flex items-center">
                              <PlusCircleIcon className="h-6 w-6 mr-2" /> Add my first Product
                            </span>
                          }
                          extraClasses="uppercase py-3"
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      <div>
                        {'Product' === activeTab && (
                          <ProductTab
                            products={products}
                            setOpen={setProductModalOpen}
                            setEditingItem={setEditingProduct}
                            setMetricsViewProductId={setMetricsViewProductId}
                          />
                        )}
                      </div>
                      <div>
                        {'Promotion' === activeTab && (
                          <PromotionTab
                            products={products}
                            setOpen={setPromotionModalOpen}
                            setEditingItem={setEditingProduct}
                          />
                        )}
                      </div>
                      <div>
                        {'Advertising' === activeTab && (
                          <AdvertisingTab
                            products={products}
                            setOpen={setAdvertisingModalOpen}
                            setEditingItem={setEditingProduct}
                          />
                        )}
                      </div>
                      <div>
                        {'Planning' === activeTab && (
                          <PlanningTab
                            products={products}
                            setOpen={setPlanningModalOpen}
                            setEditingItem={setEditingProduct}
                          />
                        )}
                      </div>
                      <div>
                        {'View All' === activeTab && (
                          <AllTab
                            products={products}
                            setEditingItem={setEditingProduct}
                            setMetricsViewProductId={setMetricsViewProductId}
                            setActiveTab={setActiveTab}
                            setProductOpen={setProductModalOpen}
                            setPromotionOpen={setPromotionModalOpen}
                            setAdvertisingOpen={setAdvertisingModalOpen}
                            setPlanningOpen={setPlanningModalOpen}
                          />
                        )}
                      </div>
                    </>
                  )}
                </>
              )
            )}
          </div>
        </div>
      </div>

      <FormModal
        size="md"
        open={productModalOpen}
        setOpen={setProductModalOpen}
        Form={ProductForm}
        editingItem={editingProduct}
        setEditingItem={setEditingProduct}
        deleteAction={async () => {
          await dispatch(deleteProductAsync({ id: editingProduct.id }))
        }}
      />

      <FormModal
        size="md"
        open={promotionModalOpen}
        setOpen={setPromotionModalOpen}
        Form={PromotionForm}
        editingItem={editingProduct}
        setEditingItem={setEditingProduct}
        deleteAction={async () => {
          await dispatch(deleteProductAsync({ id: editingProduct.id }))
        }}
      />

      <FormModal
        size="md"
        open={advertisingModalOpen}
        setOpen={setAdvertisingModalOpen}
        Form={AdvertisingForm}
        editingItem={editingProduct}
        setEditingItem={setEditingProduct}
        deleteAction={async () => {
          await dispatch(deleteProductAsync({ id: editingProduct.id }))
        }}
      />

      <FormModal
        size="md"
        open={planningModalOpen}
        setOpen={setPlanningModalOpen}
        Form={PlanningForm}
        editingItem={editingProduct}
        setEditingItem={setEditingProduct}
        deleteAction={async () => {
          await dispatch(deleteProductAsync({ id: editingProduct.id }))
        }}
      />

      <ReactTooltip id="units-tooltip">
        This is the number of units you plan to order from your manufacturer. If
        <br />
        you are just running the numbers and haven't gotten a MOQ (minimum order
        <br />
        quantities) from your manufacturer just put in 500 or 1000.
        <br />
      </ReactTooltip>

      <ReactTooltip id="cost-per-unit-tooltip">
        How much does it cost to manufacture each product? Ask
        <br /> your manufacurer for this information or estimate based on
        <br /> prices you see on sites like Alibaba.com.
      </ReactTooltip>

      <ReactTooltip id="shipping-cost-tooltip">
        How much does it cost to have your bulk order shipped from
        <br /> your manufacturer to your home, warehouse or FBA center? If
        <br /> you don't know this at the beginning estimate high to be safe.
        <br /> Shipping costs can vary depending on the size, weight and shipping
        <br /> method. You can find this number by multiplying your shipping cost
        <br /> per unit by the number of units you will be ordering.
      </ReactTooltip>

      <ReactTooltip id="amazon-fees-tooltip">
        Input in the total FBA fees here for a product similar to yours <br />
        in size and weight. You can find these by using the Helium 10
        <br /> XRAY chrome extension
      </ReactTooltip>

      <ReactTooltip id="amazon-retail-tooltip">
        How much will you sell this item for on Amazon? Compare to
        <br /> other products ranking on similar keywords.
      </ReactTooltip>

      <ReactTooltip id="profit-margin-tooltip">
        We recommend looking for products with profit margins
        <br /> between 35 to 50% or greater other products ranking on similar keywords.
      </ReactTooltip>

      <ReactTooltip id="promotion-units-per-day-tooltip">
        In Helium 10&apos;s{' '}
        <a
          href="https://www.helium10.com/blog/updates/keyword-research-tool-magnet-2/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Magnet Tool
        </a>
        , check the CPR 8 Day Giveaway
        <br /> number on your desired keywords. Divide this number by 8<br /> and you have the
        recommended amount of units you need to
        <br /> move every day to be on the front page for that keyword. You
        <br /> can do this through Giveaways or Advertising or a mix of
        <br /> both.
      </ReactTooltip>

      <ReactTooltip id="promotion-percentage-tooltip">
        If you're doing giveaways and using One Time Use Voucher
        <br /> Codes, what percentage off are you giving? (90-99% is
        <br /> recommended)
      </ReactTooltip>

      <ReactTooltip id="promotion-days-tooltip">
        How many days are you going to do promotional giveaways
        <br /> for? (7-10 recommended)
      </ReactTooltip>

      <ReactTooltip id="daily-advertising-cost-tooltip">
        Does your product have margin to advertise? It should!
        <br /> Advertising is a key component of a successful Amazon
        <br /> business. When you're getting started we recommend
        <br /> spending 1-3x your product price on ads every day. So if your
        <br /> product is $10, plan to spend $10-30 / day on ads.
      </ReactTooltip>

      <ReactTooltip id="spend-tooltip">
        This field you can come back to after running ads for 1 week+
        <br /> and fill in to keep an eye on your ad spend to ensure you're
        <br /> being profitable.
      </ReactTooltip>

      <ReactTooltip id="sales-tooltip">
        Run your reports and plug in your sales numbers here.
      </ReactTooltip>

      <ReactTooltip id="projected-units-tooltip">
        Make sure you keep your product in stock! This column will
        <br /> help you plan when you're going to sell out - feel free to
        <br /> update it as you go with your averages. Take into
        <br /> consideration your manufacturer's lead time to make the
        <br /> product as well as shipping time.
      </ReactTooltip>
    </>
  )
}

export const InlineLink = ({ to, children }) => {
  return (
    <a
      className="text-purple-450 hover:opacity-80 cursor-pointer"
      href={to}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  )
}

export default PowerProfitTool
